import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Navbar from './Navbar';
import BottomNav from './BottomNav';
import './CouponDetails.css';
import Loader from '../pages/Loader';

function CouponDetails() {
    const { couponId } = useParams();
    const [loading, setLoading] = useState(true);
    const [coupon, setCoupon] = useState(null);
    const [attachedUsers, setAttachedUsers] = useState([]);
    const [phoneNumber, setPhoneNumber] = useState('');

    useEffect(() => {
        fetchCouponDetails();
    }, [couponId]);

    const fetchCouponDetails = async () => {
      setLoading(true);
      const response = await fetch(`${process.env.REACT_APP_API_URL}/coupon/${couponId}`, { 
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
          'Content-Type': 'application/json'
        }
       });
      if (response.ok) {
          const data = await response.json();
          setCoupon(data.couponDetails);
          // Assuming the API returns attached users in a property named 'attachedUsers'
          setAttachedUsers(data.attachedUsers || []);
          setLoading(false);
      }
  };

   // Function to attach accountType2 users
   const attachUserToCoupon = async () => {
    setLoading(true);
    // Remove any spaces or blank spaces from the phone number input
    const cleanedPhoneNumber = phoneNumber.replace(/\s/g, '');
    const response = await fetch(`${process.env.REACT_APP_API_URL}/coupon/attach`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ couponId, userPhoneNumbers: [cleanedPhoneNumber] }),
    });

    if (response.ok) {
        const data = await response.json(); // Parsing the JSON response
        fetchCouponDetails(); // Logging to verify correct data
        alert(data.message);
    } else {
        console.error("Failed to attach users:", await response.text()); // Error handling
    }
    setLoading(false);
  };


  const redeemUserFromCoupon = async (userId) => {
    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/coupon/redeem/${couponId}`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ userId }),
      });
      if (response.ok) {
        // Update the attachedUsers state to remove the redeemed user
        fetchCouponDetails();
      }
    } catch (error) {
      console.error('Error redeeming user from coupon:', error);
    } finally {
      setLoading(false);
    }
  };

  const formatDescription = (coupon) => {
    switch (coupon.discountType) {
      case 'nextPurchase':
        return `${coupon.discount}${coupon.discountNature === 'percentage' ? '%' : ' off'} on your next purchase`;
      case 'minimumSpend':
        return `${coupon.discount}${coupon.discountNature === 'percentage' ? '%' : ' off'} on spend over ${coupon.minimumSpend}`;
      case 'freeItem':
        return `Get a free ${coupon.freeItem} on spend over ${coupon.minimumSpend}`;
      case 'bogo':
        let bogoDescription = `Buy ${coupon.bogoDetails.buyItemQuantity} ${coupon.bogoDetails.buyItem}`;
        switch (coupon.bogoDetails.offerType) {
          case 'percentage':
            bogoDescription += `, get ${coupon.bogoDetails.discount}% off`;
            break;
          case 'flat':
            bogoDescription += `, get ${coupon.bogoDetails.discount} off`;
            break;
          case 'item':
            bogoDescription += `, get ${coupon.bogoDetails.getItemQuantity} ${coupon.bogoDetails.getItem} free`;
            break;
          default:
            bogoDescription += ' with a special offer';
            break;
        }
        return bogoDescription;
      default:
        return 'Special offer';
    }
  };


    return (
        <div className="coupon-details-page">
            <Navbar />
            {loading ? (
              <Loader />
            ) : (
              <main className="main-content">
            <div className="coupon-details-container">
                <h1>Coupon Details</h1>
                {coupon && (
                    <>
                        <div className="coupon-info">
                        <strong>Name:</strong>
                            <p>{coupon.name}</p>
                            <strong>Description:</strong>
                            <p>{formatDescription(coupon)}</p>
                            <strong>Valid From:</strong>
                            <p>{new Date(coupon.validFrom).toLocaleDateString()}</p>
                            <strong>Valid To:</strong>
                            <p>{new Date(coupon.validTo).toLocaleDateString()}</p>
                            <strong>Terms:</strong>
                            <ul>
                              {coupon.termsAndConditions.map((term, index) => (
                                <li key={index}>{term}</li>
                              ))}
                            </ul>
                        </div>
                        <div className="attach-user-form">
                            <input
                                type="text"
                                value={phoneNumber}
                                onChange={(e) => setPhoneNumber(e.target.value)}
                                placeholder="Enter user's phone number"
                            />
                            <button onClick={() => attachUserToCoupon()}>Attach User</button>
                        </div>
                        <div className="attached-users">
                          <h2>Attached Users</h2>
                          {attachedUsers && attachedUsers.length > 0 ? (
                              <ul>
                                  {attachedUsers.map((user) => (
                                      <li key={user.id}>
                                          {user.username ? user.username : user.phoneNumber}
                                          <button onClick={() => redeemUserFromCoupon(user.userId)}>Redeem</button>
                                      </li>
                                  ))}
                              </ul>
                          ) : (
                              <p>No users attached yet.</p> // Showing a message if no users are attached
                          )}
                        </div>

                    </>
                )}
            </div>
            <BottomNav />
            </main>
            )}
            
        </div>
    );
}

export default CouponDetails;
