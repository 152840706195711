// src/pages/HomePage.js
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './HomePage.css';
import Navbar from './Navbar';
import BottomNav from './BottomNav';
import Loader from './Loader';
import { useAuth } from '../contexts/AuthContext';

function HomePage() {
  const { user } = useAuth();
  const [loading, setLoading] = useState(true);
  const [name, setName] = useState('');
  const [discount, setDiscount] = useState('');
  const [validFrom, setValidFrom] = useState('');
  const [validTo, setValidTo] = useState('');
  const [terms, setTerms] = useState('');
  const [coupons, setCoupons] = useState([]);
  const [bills, setBills] = useState([]);
  const [totalActiveCoupons, setTotalActiveCoupons] = useState([]);
  const [discountType, setDiscountType] = useState('percentage'); // Default to 'percentage'

  const navigate = useNavigate();

  useEffect(() => {
    const fetchCoupons = async () => {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/coupons`, { 
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
          'Content-Type': 'application/json'
        }
       });
      if (response.ok) {
        const data = await response.json();
        setCoupons(data.coupons);
        setTotalActiveCoupons(data.totalActiveCoupons);
        setLoading(false);
      }
    };

    const fetchBills = async () => {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/bills`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        setBills(data);
        
        setLoading(false);
      }
    };
    
    fetchCoupons();
    fetchBills();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Assuming you're using HTTP-only cookies, you don't need to manually handle the token
    const response = await fetch(`${process.env.REACT_APP_API_URL}/coupon`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ 
        name, 
        discount, 
        discountType, 
        validFrom, 
        validTo, 
        terms 
      }),
    });
    if (response.ok) {
      // Handle successful coupon creation (e.g., clear form, show success message)
    } else {
      // Handle error
    }
  };

  const handleLogout = async () => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/logout`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
          'Content-Type': 'application/json'
        } // Needed if you're using cookies
    });
    
    if (response.ok) {
        // Optionally clear client-side state, local storage, etc.
        navigate('/login');
    }
};

const formatDescription = (coupon) => {
  switch (coupon.discountType) {
    case 'nextPurchase':
      return `${coupon.discount}${coupon.discountNature === 'percentage' ? '%' : ' off'} on your next purchase`;
    case 'minimumSpend':
      return `${coupon.discount}${coupon.discountNature === 'percentage' ? '%' : ' off'} on spend over ${coupon.minimumSpend}`;
    case 'freeItem':
      return `Get a free ${coupon.freeItem} on spend over ${coupon.minimumSpend}`;
    case 'bogo':
      let bogoDescription = `Buy ${coupon.bogoDetails.buyItemQuantity} ${coupon.bogoDetails.buyItem}`;
      switch (coupon.bogoDetails.offerType) {
        case 'percentage':
          bogoDescription += `, get ${coupon.bogoDetails.discount}% off`;
          break;
        case 'flat':
          bogoDescription += `, get ${coupon.bogoDetails.discount} off`;
          break;
        case 'item':
          bogoDescription += `, get ${coupon.bogoDetails.getItemQuantity} ${coupon.bogoDetails.getItem} free`;
          break;
        default:
          bogoDescription += ' with a special offer';
          break;
      }
      return bogoDescription;
    default:
      return 'Special offer';
  }
};


  return (
  <div className="homepage">
    <Navbar />

    {loading ? (
        <Loader />
      ) : (
        <main className="main-content">
  <section className="dashboard-metrics">
    <div className="metric-card">
      <div className="metric-value">{totalActiveCoupons}</div>
      <div className="metric-label">Active Coupons Held by Your Customers</div>
    </div>
    <div className="metric-card">
      <div className="metric-value">{bills?.length}</div>
      <div className="metric-label">Outstanding Bills</div>
    </div>
  </section>

  <section className="main-actions">
    <button className="action-button" onClick={() => navigate('/create-coupon')}>Create Coupon</button>
    <button className="action-button" onClick={() => navigate('/create-bill')}>Create Bill</button>
    <button className="action-button" onClick={() => navigate('/coupons')}>View Coupons</button>
    <button className="action-button" onClick={() => navigate('/bills')}>View Bills</button>
  </section>

  <h1>Your Coupons</h1>
  <section className="scrollable-cards">
    {coupons.length ? (
          coupons.map((coupon) => (
            <div key={coupon._id} className="coupons" onClick={() => navigate(`/coupons/${coupon._id}`)}>
              <h3 className="coupon-name">{coupon.name}</h3>
              <p className="coupon-description">{formatDescription(coupon)}</p>
              <p className="coupon-expiry">Expires at: {new Date(coupon.validTo).toLocaleDateString()}</p>
            </div>
          ))
        ) : (
          <p>No coupons available.</p>
        )}
  </section>

  <h1>Your Bills</h1>
  <section className="scrollable-cards">
    {bills.length ? (
      bills.map((bill) => (
        <div key={bill._id} className="bills" onClick={() => navigate(`/bills/${bill._id}`)}>
          <div className="bill-id">Bill ID: {bill._id}</div>
          <div className="retailer-name">Date of bill: {new Date(bill.createdAt).toLocaleDateString()}</div>
          <div className="total-amount">Total Amount: {bill.totalAmount}</div>
        </div>
      ))
    ) : (
      <p>No bills available.</p>
    )}
  </section>


  <BottomNav />
  </main>
      )}
</div>

  );
}

export default HomePage;
