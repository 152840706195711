// AnalyticsPage.js

import React from 'react';
import Navbar from './Navbar';
import BottomNav from './BottomNav';
import './AnalyticsPage.css';

const AnalyticsPage = () => {
  return (
    <div>
      <Navbar />
      <main className="main-content analytics-container">
        <h1>Analytics</h1>
        <p>Analytics features are coming soon!</p>
      </main>
      <BottomNav />
    </div>
  );
};

export default AnalyticsPage;
