// SupportPage.js
import React, { useState } from 'react';
import Navbar from './Navbar';
import BottomNav from './BottomNav';
import './SupportPage.css'; // Create this CSS file for styling
import Loader from '../pages/Loader';

function SupportPage() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/submit-help-form`, {
        method: 'POST',
        headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        },
        body: JSON.stringify({
          message: message
        })
      });

      if (!response.ok) {
        throw new Error('Failed to submit support request');
      }

      setSuccessMessage('Support request submitted successfully.');
    } catch (error) {
      console.error(error);
      setError('An error occurred while submitting the support request.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Navbar />
      <main className="main-content">
        <div className="support-page">
          <h1>Support and Help</h1>
          <p>If you need assistance or have any questions, please don't hesitate to contact us using the form below:</p>
          <form className="contact-form" onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="message">Message:</label>
              <textarea id="message" name="message" value={message} onChange={(e) => setMessage(e.target.value)} required></textarea>
            </div>
            {loading ? (
              <button type="submit" disabled>Loading...</button>
            ) : (
              <button type="submit">Submit</button>
            )}
            {error && <p className="error-message">{error}</p>}
            {successMessage && <p className="success-message">{successMessage}</p>}
          </form>
        </div>
      </main>
      <BottomNav />
    </div>
  );
}

export default SupportPage;
