// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext'; // Adjust the import path as needed
import ProtectedRoute from './components/ProtectedRoute';

import Login from './pages/Login';
import HomePage from './pages/HomePage';
import CouponDetails from './pages/CouponDetails';
import CreateCouponForm from './pages/CreateCouponForm';
import CreateBillForm from './pages/CreateBillForm';
import ViewCoupons from './pages/ViewCoupons';
import AccountPage from './pages/AccountPage';
import BillDetailsPage from './pages/BillDetailsPage';
import ProfilePage from './pages/ProfilePage';
import UpdatePasswordPage from './pages/UpdatePasswordPage';
import SupportPage from './pages/SupportPage';
import TermsPage from './pages/TermsPage';
import MyBillsPage from './pages/MyBillsPage';
import AnalyticsPage from './pages/AnalyticsPage';

function App() {
  return (
    <AuthProvider>
      <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<ProtectedRoute><HomePage /></ProtectedRoute>} />
        <Route path="/account" element={<ProtectedRoute><AccountPage /></ProtectedRoute>} />
        <Route path="/account/profile" element={<ProtectedRoute><ProfilePage /></ProtectedRoute>} />
        <Route path="/account/update-password" element={<ProtectedRoute><UpdatePasswordPage /></ProtectedRoute>} />
        <Route path="/account/support" element={<ProtectedRoute><SupportPage /></ProtectedRoute>} />
        <Route path="/account/terms" element={<ProtectedRoute><TermsPage /></ProtectedRoute>} />
        <Route path="/coupons/:couponId" element={<ProtectedRoute><CouponDetails /></ProtectedRoute>} />
        <Route path="/create-coupon" element={<ProtectedRoute><CreateCouponForm /></ProtectedRoute>} />
        <Route path="/create-bill" element={<ProtectedRoute><CreateBillForm /></ProtectedRoute>} />
        <Route path="/create-bill/:billId" element={<ProtectedRoute><CreateBillForm /></ProtectedRoute>} />
        <Route path="/bills" element={<ProtectedRoute><MyBillsPage /></ProtectedRoute>} />
        <Route path="/bills/:billId" element={<ProtectedRoute><BillDetailsPage /></ProtectedRoute>} />
        <Route path="/coupons" element={<ProtectedRoute><ViewCoupons /></ProtectedRoute>} />
        <Route path="/analytics" element={<AnalyticsPage />} />
      </Routes>
    </Router>
    </AuthProvider>
  );
}

export default App;
