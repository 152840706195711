// src/pages/ViewCoupons.js
import React, { useEffect, useState } from 'react';
import Navbar from './Navbar';
import BottomNav from './BottomNav';
import { useNavigate } from 'react-router-dom';
import './ViewCoupons.css';
import Loader from '../pages/Loader';

function ViewCoupons() {
  const [coupons, setCoupons] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCoupons = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/coupons`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
            'Content-Type': 'application/json'
          } // for CORS and sending cookies
        });
        if (response.ok) {
          const data = await response.json();
          setCoupons(data.coupons);
        } else {
          // Handle errors or redirects if not authenticated
          console.error('Failed to fetch coupons');
          navigate('/login');
        }
      } catch (error) {
        console.error('Error fetching coupons:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchCoupons();
  }, [navigate]);

  const formatDescription = (coupon) => {
    switch (coupon.discountType) {
      case 'nextPurchase':
        return `${coupon.discount}${coupon.discountNature === 'percentage' ? '%' : ' off'} on your next purchase`;
      case 'minimumSpend':
        return `${coupon.discount}${coupon.discountNature === 'percentage' ? '%' : ' off'} on spend over ${coupon.minimumSpend}`;
      case 'freeItem':
        return `Get a free ${coupon.freeItem} on spend over ${coupon.minimumSpend}`;
      case 'bogo':
        let bogoDescription = `Buy ${coupon.bogoDetails.buyItemQuantity} ${coupon.bogoDetails.buyItem}`;
        switch (coupon.bogoDetails.offerType) {
          case 'percentage':
            bogoDescription += `, get ${coupon.bogoDetails.discount}% off`;
            break;
          case 'flat':
            bogoDescription += `, get ${coupon.bogoDetails.discount} off`;
            break;
          case 'item':
            bogoDescription += `, get ${coupon.bogoDetails.getItemQuantity} ${coupon.bogoDetails.getItem} free`;
            break;
          default:
            bogoDescription += ' with a special offer';
            break;
        }
        return bogoDescription;
      default:
        return 'Special offer';
    }
  };


  return (
    <div>
      <Navbar />
      {loading ? (
        <Loader />
      ) : (
        <main className="main-content">
      <div className="coupons-list">
        <h1>Your Coupons</h1>
        {coupons.length ? (
          coupons.map((coupon) => (
            <div key={coupon._id} className="coupon-card" onClick={() => navigate(`/coupons/${coupon._id}`)}>
              <h3 className="coupon-name">{coupon.name}</h3>
              <p className="coupon-description">{formatDescription(coupon)}</p>
              <p className="coupon-expiry">Expires at: {new Date(coupon.validTo).toLocaleDateString()}</p>
            </div>
          ))
        ) : (
          <p>No coupons available.</p>
        )}
      </div>
      <BottomNav />
      </main>
      )}
    </div>
  );
}

export default ViewCoupons;

