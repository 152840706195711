// src/components/BottomNav.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import './HomePage.css';

const BottomNav = () => {
  const navigate = useNavigate();

  return (
    <nav className="bottom-nav">
      <div className="nav-item active" onClick={() => navigate('/')}>Home</div>
      <div className="nav-item" onClick={() => navigate('/account')}>Account</div>
      <div className="nav-item" onClick={() => navigate('/analytics')}>Analytics</div>
    </nav>
  );
};

export default BottomNav;
