// ProfilePage.js
import React from 'react';
import Navbar from './Navbar';
import BottomNav from './BottomNav';
import './ProfilePage.css'; // Make sure to create this CSS file
import { useAuth } from '../contexts/AuthContext';
import Loader from '../pages/Loader';

function ProfilePage() {
    const { user } = useAuth();
  // Fetch user profile details from API or context

  return (
    <div>
      <Navbar />
      <main className="main-content">
        <div className="profile-page">
          <h1>Profile Details</h1>
          <div><h3>Name:</h3> {user.username}</div>
          <div><h3>Address:</h3> {user.address}</div>
          <div><h3>Joined On:</h3> {new Date(user.joinedOn).toLocaleDateString()}</div>
        </div>
      </main>
      <BottomNav />
    </div>
  );
}

export default ProfilePage;
