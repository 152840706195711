import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import './BillDetailsPage.css';
import Navbar from './Navbar';
import BottomNav from './BottomNav';
import Loader from '../pages/Loader';

const BillDetailsPage = () => {
  const { billId } = useParams();
  const [loading, setLoading] = useState(true);
  const [billDetails, setBillDetails] = useState(null); // State to store bill details

  // Function to fetch bill details from the API
  const fetchBillDetails = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/bills/${billId}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
          'Content-Type': 'application/json'
        },
      });

      if (response.ok) {
        const data = await response.json();
        setBillDetails(data); // Set the fetched bill details in state
        console.log(billDetails);
      } else {
        console.error('Failed to fetch bill details:', response.data);
      }
    } catch (error) {
      console.error('Error fetching bill details:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // Fetch bill details when the component mounts
    fetchBillDetails();
  }, []);

   // Function to navigate to the create bill page with the billId
   const handleUpdateBill = () => {
    window.location.href = `/create-bill/${billId}`;
  };

  return (
    <div className="bill-container">
      <Navbar />
      {loading ? (
        <Loader />
      ) : (
        <main className="main-content">
          {billDetails && (
            <div>
              <div className="bill-header">
              <h2>Your bill</h2>
              </div>
              <div className="bill-info">
                <p><strong>Retail Name:</strong> {billDetails.retailName}</p>
                <p><strong>Retail Address:</strong> {billDetails.retailAddress}</p>
                <p><strong>Bill ID:</strong> {billDetails._id}</p>
                <p><strong>Date of Bill:</strong> {new Date(billDetails.createdAt).toLocaleDateString()}</p>
              </div>
              <table className="bill-table">
                <thead>
                  <tr>
                    <th>Description</th>
                    <th>Qty.</th>
                    <th>Rate</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {billDetails.items.map((item, index) => (
                    <tr key={index}>
                      <td>{item.description}</td>
                      <td>{item.count}</td>
                      <td>₹{item.amount.toFixed(2)}</td>
                      <td>₹{(item.amount * item.count).toFixed(2)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="bill-total">
                <strong>Total: ₹{billDetails.totalAmount.toFixed(2)}</strong>
              </div>
              <button onClick={handleUpdateBill} className="update-bill-button">Update Bill</button>
            </div>
          )}
        
        <BottomNav />
      </main>
      )}
    </div>
  );
};

export default BillDetailsPage;
