// UpdatePasswordPage.js
import React, { useState } from 'react';
import Navbar from './Navbar';
import BottomNav from './BottomNav';
import './ProfilePage.css'; // Assuming you have a shared CSS file
import Loader from '../pages/Loader';

function UpdatePasswordPage() {
  const [currentPassword, setCurrentPassword] = useState('');
  const [loading, setLoading] = useState(true);
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (newPassword !== confirmNewPassword) {
      setMessage('New passwords do not match.');
      return;
    }

    // Implement your API call to update the password
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/update-password`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        },
        body: JSON.stringify({ currentPassword, newPassword })
      });

      if (response.ok) {
        setMessage('Password updated successfully.');
      } else {
        const data = await response.json();
        setMessage(data.message);
      }
    } catch (error) {
      console.error('Error updating password:', error);
      setMessage('An error occurred while updating the password.');
    }
  };

  return (
    <div>
      <Navbar />
      <main className="main-content">
        <div className="profile-page">
          <h1>Update Password</h1>
          <form className="update-password-form" onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="currentPassword">Current Password</label>
              <input type="password" id="currentPassword" value={currentPassword} onChange={(e) => setCurrentPassword(e.target.value)} required />
            </div>
            <div className="form-group">
              <label htmlFor="newPassword">New Password</label>
              <input type="password" id="newPassword" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} required />
            </div>
            <div className="form-group">
              <label htmlFor="confirmNewPassword">Confirm New Password</label>
              <input type="password" id="confirmNewPassword" value={confirmNewPassword} onChange={(e) => setConfirmNewPassword(e.target.value)} required />
            </div>
            <button type="submit" className="update-password-button">Update Password</button>
            {message && <div className="message">{message}</div>}
          </form>
        </div>
      </main>
      <BottomNav />
    </div>
  );
}

export default UpdatePasswordPage;
