// src/components/Navbar.js
import React from 'react';
import './HomePage.css';
import { useAuth } from '../contexts/AuthContext';

const Navbar = () => {
  const { user } = useAuth();

  return (
    <nav className="navbar">
      <div className="brand">
      <img src="/purtim.jpeg" alt="Logo" className="navbar-logo" />
        <span className="brand-name">{user?.username}</span>
      </div>
    </nav>
  );
};

export default Navbar;
