// AccountPage.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Navbar from './Navbar';
import BottomNav from './BottomNav';
import './AccountPage.css'; // Make sure to create this CSS file
import { useAuth } from '../contexts/AuthContext';

function AccountPage() {
  const navigate = useNavigate();
  const { logout } = useAuth();

  return (
    <div>
      <Navbar />
      <main className="main-content">
      <div  className="account-page">
      <div className="account-options">
        <button onClick={() => navigate('/account/profile')} className="account-option">Profile</button>
        <button onClick={() => navigate('/account/update-password')} className="account-option">Update Password</button>
        <button onClick={() => navigate('/account/support')} className="account-option">Support and Help</button>
            <button onClick={() => navigate('/account/terms')} className="account-option">Terms and Conditions</button>
        <button onClick={ async () => {
          // Implement logout functionality
          await logout();
          navigate('/');
        }} className="account-option logout">Logout</button>
      </div>
      </div>
      <BottomNav />
      </main>
    </div>
  );
}

export default AccountPage;
