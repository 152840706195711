import React, { useState } from 'react';
import './CreateCouponForm.css'; // Make sure this CSS file exists and is styled correctly
import Navbar from './Navbar'; // Make sure you have this component
import BottomNav from './BottomNav'; // Make sure you have this component
import Loader from '../pages/Loader';

function CreateCouponForm() {
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({
    name: '',
    discount: '',
    discountNature: 'percentage', // This might not be needed depending on your form structure
    minimumSpend: '',
    freeItem: '',
    buyItem: '',
    buyItemQuantity: '',
    offerType: 'percentage', // Added for bogoDetails structure
    getItem: '',
    getItemQuantity: '',
    discountOnGetQuantity: '', // Consider if this fits with your new structure or if discount covers it
    validFrom: '',
    validTo: '',
    termsAndConditions: [''],
    creatorName: '',
    creatorLocation: '',
    discountType: 'nextPurchase',
  });
  

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Adjust bogoDetails according to the offerType
    let bogoDetails = undefined;
    if (formData.discountType === 'bogo') {
      bogoDetails = {
        buyItem: formData.buyItem,
        buyItemQuantity: parseInt(formData.buyItemQuantity),
        offerType: formData.offerType,
      };
  
      // Add specific fields based on offerType
      if (formData.offerType === 'item') {
        bogoDetails.getItem = formData.getItem;
        bogoDetails.getItemQuantity = parseInt(formData.getItemQuantity);
      } else {
        // For 'percentage' or 'flat' offerTypes
        bogoDetails.discount = parseInt(formData.discount);
      }
    }
  
    const adjustedFormData = {
      ...formData,
      termsAndConditions: formData.termsAndConditions, // Assuming terms are separated by newline characters
      bogoDetails: bogoDetails,
    };
  
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/coupon`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(adjustedFormData),
      });
      if (response.ok) {
        alert('Coupon created successfully!');
        // Optionally reset the form here or navigate away
      } else {
        alert('Failed to create coupon.');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      alert('Error creating coupon.');
    }
  };
  

  const handleAddTerm = () => {
    setFormData({
      ...formData,
      termsAndConditions: [...formData.termsAndConditions, '']
    });
  };
  
  const handleRemoveTerm = index => {
    setFormData({
      ...formData,
      termsAndConditions: formData.termsAndConditions.filter((_, i) => i !== index)
    });
  };
  
  const handleTermsChange = (index, value) => {
    const updatedTerms = formData.termsAndConditions.map((term, i) => {
      if (i === index) return value;
      return term;
    });
  
    setFormData({
      ...formData,
      termsAndConditions: updatedTerms
    });
  };
  

  // UI for form goes here
  // This would include inputs for all fields in the formData state,
  // including specialized inputs for handling bogoDetails, tieredDiscounts, etc.
  // The actual implementation of these inputs and their change handlers can get quite detailed,
  // especially for nested objects and arrays, and would require custom logic for adding/removing
  // elements from arrays like termsAndConditions and tieredDiscounts.

  return (
    <div>
  <Navbar />
  <main className="main-content">
  <form onSubmit={handleSubmit} className="create-coupon-form">
    <h1>Create Coupon</h1>
    <div className="form-group">
      <label htmlFor="name">Coupon Name: </label>
      <input type="text" id="name" name="name" value={formData.name} onChange={handleInputChange} required />
    </div>

    <div className="form-group">
      <label htmlFor="discountType">Discount Type: </label>
      <select id="discountType" name="discountType" value={formData.discountType} onChange={handleInputChange} required>
        <option value="nextPurchase">Next Purchase Off</option>
        <option value="minimumSpend">Minimum Spend Off</option>
        <option value="freeItem">Get Free Something on Spend</option>
        <option value="bogo">Buy One Get One</option>
      </select>
    </div>


    {formData.discountType === 'nextPurchase' || formData.discountType === 'minimumSpend' ? (
      <>
        <div className="form-group">
          <label htmlFor="discountNature">Discount Nature: </label>
          <select id="discountNature" name="discountNature" value={formData.discountNature} onChange={handleInputChange} required>
            <option value="percentage">Percentage</option>
            <option value="flat">Flat Amount</option>
          </select>
        </div>

        <div className="form-group">
          <label htmlFor="discount">Discount: </label>
          {formData.discountNature === 'percentage' ? (
            <input
              type="number"
              id="discount"
              name="discount"
              placeholder="Enter discount percentage"
              value={formData.discount}
              onChange={handleInputChange}
              required
            />
          ) : (
            <input
              type="number"
              id="discount"
              name="discount"
              placeholder="Enter discount amount"
              value={formData.discount}
              onChange={handleInputChange}
              required
            />
          )}
        </div>
      </>
    ) : null}


    {formData.discountType === 'minimumSpend' || formData.discountType === 'freeItem' ? (
      <div className="form-group">
        <label htmlFor="minimumSpend">Minimum Amount</label>
        <input type="number" id="minimumSpend" name="minimumSpend" value={formData.minimumSpend} onChange={handleInputChange} required />
      </div>
    ) : null}

    {formData.discountType === 'freeItem' ? (
      <div className="form-group">
        <label htmlFor="freeItem">Free Item Description</label>
        <input type="text" id="freeItem" name="freeItem" value={formData.freeItem} onChange={handleInputChange} required />
      </div>
    ) : null}

    {/* Additional inputs for BOGO discount type */}
    {formData.discountType === 'bogo' && (
      <>
        <div className="form-group">
          <label htmlFor="buyItem">Buy Item Description</label>
          <input
            type="text"
            id="buyItem"
            name="buyItem"
            value={formData.buyItem}
            onChange={handleInputChange}
            placeholder="Item customer needs to buy"
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="buyItemQuantity">Buy Item Quantity</label>
          <input
            type="number"
            id="buyItemQuantity"
            name="buyItemQuantity"
            value={formData.buyItemQuantity}
            onChange={handleInputChange}
            placeholder="Quantity of buy item"
            min="1"
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="offerType">Offer Type</label>
          <select id="offerType" name="offerType" value={formData.offerType} onChange={handleInputChange}>
            <option value="percentage">Percentage Off</option>
            <option value="flat">Flat Discount</option>
            <option value="item">Get Item</option>
          </select>
        </div>

        {formData.offerType !== 'item' && (
          <div className="form-group">
            <label htmlFor="discount">{formData.offerType === 'percentage' ? 'Discount Percentage' : 'Discount Amount'}</label>
            <input
              type="number"
              id="discount"
              name="discount"
              value={formData.discount}
              onChange={handleInputChange}
              placeholder={formData.offerType === 'percentage' ? 'Enter discount percentage' : 'Enter discount amount'}
              required
            />
          </div>
        )}

        {formData.offerType === 'item' && (
          <>
            <div className="form-group">
              <label htmlFor="getItem">Get Item Description</label>
              <input
                type="text"
                id="getItem"
                name="getItem"
                value={formData.getItem}
                onChange={handleInputChange}
                placeholder="Item customer gets as part of the offer"
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="getItemQuantity">Get Item Quantity</label>
              <input
                type="number"
                id="getItemQuantity"
                name="getItemQuantity"
                value={formData.getItemQuantity}
                onChange={handleInputChange}
                placeholder="Quantity of get item"
                min="1"
                required
              />
            </div>
          </>
        )}
      </>
    )}




    <div className="form-group">
      <label htmlFor="validFrom">Valid From</label>
      <input type="date" id="validFrom" name="validFrom" value={formData.validFrom} onChange={handleInputChange} required />
    </div>

    <div className="form-group">
      <label htmlFor="validTo">Valid To</label>
      <input type="date" id="validTo" name="validTo" value={formData.validTo} onChange={handleInputChange} required />
    </div>

    <div className="form-group">
      <label>Terms & Conditions</label>
      {formData.termsAndConditions.map((term, index) => (
        <div key={index}>
          <textarea
            id={`termsAndConditions${index}`}
            name="termsAndConditions"
            value={term}
            onChange={e => handleTermsChange(index, e.target.value)}
            placeholder="Enter a term or condition"
            required
          />
          <button type="button" onClick={() => handleRemoveTerm(index)}>Remove</button>
        </div>
      ))}
      <button type="button" onClick={handleAddTerm}>Add Another Term</button>
    </div>


    <button type="submit" className="form-submit-button">Create Coupon</button>
  </form>
  <BottomNav />
  </main>
</div>

  );
  
}

export default CreateCouponForm;
