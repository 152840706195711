import React, { useEffect, useState } from 'react';
import Navbar from './Navbar';
import BottomNav from './BottomNav';
import { useNavigate } from 'react-router-dom';
import './MyBillsPage.css';

function MyBillsPage() {
  const [bills, setBills] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchBills = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/bills`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
            'Content-Type': 'application/json'
          } // for CORS and sending cookies
        });
        if (response.ok) {
          const data = await response.json();
          setBills(data);
        } else {
          // Handle errors or redirects if not authenticated
          console.error('Failed to fetch bills');
          navigate('/login');
        }
      } catch (error) {
        console.error('Error fetching bills:', error);
      }
    };

    fetchBills();
  }, [navigate]);

  return (
    <div>
      <Navbar />
      <main className="main-content">
        <div className="bills-list">
          <h1>Your Bills</h1>
          {bills.length ? (
            bills.map((bill) => (
              <div key={bill._id} className="bill-card" onClick={() => navigate(`/bills/${bill._id}`)}>
                <h3 className="bill-id">Bill ID: {bill._id}</h3>
                <p className="retailer-name">Date of bill: {new Date(bill.createdAt).toLocaleDateString()}</p>
                <p className="total-amount">Total Amount: {bill.totalAmount}</p>
              </div>
            ))
          ) : (
            <p>No bills available.</p>
          )}
        </div>
      </main>
      <BottomNav />
    </div>
  );
}

export default MyBillsPage;
