// TermsPage.js
import React from 'react';
import Navbar from './Navbar';
import BottomNav from './BottomNav';
import './TermsPage.css'; // Create this CSS file for styling

function TermsPage() {
  return (
    <div>
      <Navbar />
      <main className="main-content">
        <div className="terms-page">
          <h1>Terms and Conditions</h1>
          <p>Welcome to our platform! These terms and conditions outline the rules and regulations for the use of our website.</p>
          <h2>1. Introduction</h2>
          <p>By accessing this website, we assume you accept these terms and conditions in full. Do not continue to use our website if you do not accept all of the terms and conditions stated on this page.</p>
          <h2>2. Intellectual Property Rights</h2>
          <p>Other than the content you own, under these terms, we and/or our licensors own all the intellectual property rights and materials contained in this website.</p>
          <h2>3. Restrictions</h2>
          <p>You are specifically restricted from all of the following:</p>
          <ul>
            <li>publishing any website material in any other media;</li>
            <li>selling, sublicensing and/or otherwise commercializing any website material;</li>
            <li>publicly performing and/or showing any website material;</li>
            <li>using this website in any way that is or may be damaging to this website;</li>
            <li>using this website in any way that impacts user access to this website;</li>
            <li>using this website contrary to applicable laws and regulations, or in any way may cause harm to the website, or to any person or business entity;</li>
            <li>engaging in any data mining, data harvesting, data extracting or any other similar activity in relation to this website;</li>
            <li>using this website to engage in any advertising or marketing.</li>
          </ul>
          <h2>4. Limitation of liability</h2>
          <p>In no event shall we be liable for any damages arising out of the use or inability to use our website.</p>
          <h2>5. Variation of Terms</h2>
          <p>We are permitted to revise these terms at any time as we see fit, and by using this website you are expected to review these terms on a regular basis.</p>
          <h2>6. Governing Law & Jurisdiction</h2>
          <p>These terms will be governed by and construed in accordance with the laws of [Country], and you submit to the non-exclusive jurisdiction of the state and federal courts located in [Country] for the resolution of any disputes.</p>
        </div>
      </main>
      <BottomNav />
    </div>
  );
}

export default TermsPage;
