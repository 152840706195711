// Login.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Login.css'; // Make sure to create and import a corresponding CSS file
import { useAuth } from '../contexts/AuthContext';
import Loader from '../pages/Loader';

const Login = () => {
  const [loading, setLoading] = useState(true);
  const [loginDetails, setLoginDetails] = useState({
    username: '',
    password: ''
  });
  const navigate = useNavigate();
  const { login } = useAuth();
  useEffect(() => {
    // Check if the user is already logged in
    const checkLoggedIn = async () => {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/check-auth`, { 
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
          'Content-Type': 'application/json'
        }
       });
      const data = await response.json();
      if (data.user) navigate('/');
    };
    checkLoggedIn();
  }, []);

  const handleChange = (e) => {
    setLoginDetails({ ...loginDetails, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    const { username, password } = loginDetails;
    e.preventDefault();
    // Call your login function from context
    await login(username, password);
    navigate('/');
  };

  return (
    <div className="login-container container">
      <div className="form-header">
        <img src="/purtim.jpeg" alt="App Logo" />
        <h1>Welcome to Retailer-Purtim</h1>
      </div>
      <form className="login-form form" onSubmit={handleSubmit}>
        <h2>Login</h2>
        
        <label htmlFor="username">Username</label>
        <input
          type="text"
          id="username"
          name="username"
          value={loginDetails.username}
          onChange={handleChange}
          required
        />
        
        <label htmlFor="password">Password</label>
        <input
          type="password"
          id="password"
          name="password"
          value={loginDetails.password}
          onChange={handleChange}
          required
        />

        <button type="submit">Login</button>
        <div className="login-options">
          <a onClick={() => navigate('/forgot-password')}>Forgot password?</a>
        </div>
      </form>
    </div>
  );
};

export default Login;
