import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Navbar from './Navbar';
import BottomNav from './BottomNav';
import './CreateBillForm.css';
import Loader from '../pages/Loader';

function CreateBillForm() {
  const { billId } = useParams();
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState([{ description: '', count: 1, amount: '' }]);
  const [dueDate, setDueDate] = useState('');
  const [acc2PhoneNumber, setAcc2PhoneNumber] = useState('');
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    console.log(billId);
    if (billId) {
      // Fetch bill data based on billId if editing an existing bill
      fetchBillData();
    } else {
      setLoading(false);
    }
  }, [billId]);

  const fetchBillData = async () => {
    try {
      // Fetch bill data based on billId from the API
      const response = await fetch(`${process.env.REACT_APP_API_URL}/bills/${billId}`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        }
      });

      if (response.ok) {
        const billData = await response.json();
        // Populate form fields with bill data
        setItems(billData.items);
        setDueDate(billData.dueDate);
        setAcc2PhoneNumber(billData.acc2PhoneNumber);
      } else {
        alert('Failed to fetch bill data');
      }
    } catch (error) {
      console.error('Error fetching bill data:', error);
      alert('Error fetching bill data. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleItemChange = (index, field, value) => {
    const newItems = [...items];
    newItems[index][field] = value;
    setItems(newItems);
  };

  const addItem = () => {
    setItems([...items, { description: '', count: 1, amount: '' }]);
  };

  const removeItem = (index) => {
    const newItems = [...items];
    newItems.splice(index, 1);
    setItems(newItems);
  };

  const totalAmount = items.reduce((total, item) => total + (item.amount * item.count), 0);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);

    const billData = {
      items,
      dueDate,
      acc2PhoneNumber,
      totalAmount
    };

    try {
      const apiUrl = billId ? `${process.env.REACT_APP_API_URL}/update-bill/${billId}` : `${process.env.REACT_APP_API_URL}/create-bill`;
      const method = billId ? 'PUT' : 'POST';

      const response = await fetch(apiUrl, {
        method,
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(billData),
      });

      setSubmitting(false);

      if (response.ok) {
        const data = await response.json();
        const message = billId ? 'Bill updated successfully!' : data.message;
        alert(message);
        // Reset the form or navigate to another page
      } else {
        const errorText = await response.text();
        const errorMessage = billId ? `Failed to update bill: ${errorText}` : `Failed to create bill: ${errorText}`;
        alert(errorMessage);
      }
    } catch (error) {
      console.error('Error creating/updating bill:', error);
      const errorMessage = billId ? 'Error updating bill. Please try again.' : 'Error creating bill. Please try again.';
      alert(errorMessage);
      setSubmitting(false);
    }
  };

  return (
    <div>
      <Navbar />
      <main className="main-content">
        {loading ? (
          <Loader />
        ) : (
          <form onSubmit={handleSubmit} className="create-bill-form">
            <h1>{billId ? 'Edit Bill' : 'Create Bill'}</h1>
            {items.map((item, index) => (
              <div key={index} className="item-group">
                <input
                  type="text"
                  placeholder="Description"
                  value={item.description}
                  onChange={(e) => handleItemChange(index, 'description', e.target.value)}
                  className="item-input"
                />
                <input
                  type="number"
                  placeholder="Count"
                  value={item.count}
                  onChange={(e) => handleItemChange(index, 'count', Number(e.target.value))}
                  className="item-input"
                />
                <input
                  type="number"
                  placeholder="Amount"
                  value={item.amount}
                  onChange={(e) => handleItemChange(index, 'amount', Number(e.target.value))}
                  className="item-input"
                />
                <button type="button" onClick={() => removeItem(index)} className="remove-item">Remove</button>
              </div>
            ))}
            <button type="button" onClick={addItem} className="add-item-button">Add Item</button>
            <input
              type="tel"
              placeholder="Customer's Phone Number"
              value={acc2PhoneNumber}
              onChange={(e) => setAcc2PhoneNumber(e.target.value)}
              className="form-input"
            />
            <p className="total-amount">Total Amount: {totalAmount.toFixed(2)}</p>
            <button type="submit" className="form-submit-button" disabled={submitting}>
              {submitting ? 'Creating/Updating...' : billId ? 'Update Bill' : 'Create Bill'}
            </button>
          </form>
        )}
        <BottomNav />
      </main>
    </div>
  );
}

export default CreateBillForm;
